import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePlantillaList() {
  // Use toast
  const toast = useToast()

  const refPlantillaListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'id', sortable: true },
    { key: 'titulo', label: 'Titulo', sortable: true },
    { key: 'type', label: 'Tipo', sortable: true },
    { key: 'campos', label: 'Campos', sortable: false },
    { key: 'actions', label: '', thStyle: { minWidth: '120px' } },
  ]
  const perPage = ref(50)
  const totalPlantillas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const typeFilter = ref(null)
  const isFiltering = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refPlantillaListTable.value ? refPlantillaListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPlantillas.value,
    }
  })

  const refetchData = () => {
    refPlantillaListTable.value.refresh()
  }

  const filterData = () => {
    isFiltering.value = true
    if (refPlantillaListTable.value) refetchData()
  }

  const clearFilter = () => {
    isFiltering.value = false
    typeFilter.value = null
    refetchData()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchPlantillas = (ctx, callback) => {
    store
      .dispatch('app-plantilla/fetchPlantillas', {
        body: {
          search: searchQuery.value,
          type: typeFilter.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `diseno_plantillas.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalPlantillas.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Plantillas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listPlantillas = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-plantilla/listPlantillas', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `diseno_plantillas.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalPlantillas.value = total
        const parsedP = []
        const parsedPies = []
        const parsedCabeceras = []
        res.data.forEach(element => {
          if (element.type === 'CONTENIDO') {
            parsedP.push({
              key: element.id,
              label: element.titulo,
              campos: element.campos,
              body_html: element.body_html,
            })
          } else if (element.type === 'PIE') {
            parsedPies.push({
              key: element.id,
              label: element.titulo,
              campos: element.campos,
              body_html: element.body_html,
            })
          } else if (element.type === 'CABECERA') {
            parsedCabeceras.push({
              key: element.id,
              label: element.titulo,
              campos: element.campos,
              body_html: element.body_html,
            })
          }
        })
        resolve({ cabeceras: parsedCabeceras, pies: parsedPies, plantillas: parsedP })
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching departamentos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  const resolveTypeVariant = type => {
    if (type === 'CONTENIDO') return 'light-success'
    if (type === 'CABECERA') return 'light-primary'
    if (type === 'PIE') return 'light-info'
    return 'light-dark'
  }

  return {
    fetchPlantillas,
    listPlantillas,
    tableColumns,
    perPage,
    currentPage,
    totalPlantillas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlantillaListTable,
    typeFilter,
    isFiltering,
    filterData,
    clearFilter,
    resolveTypeVariant,

    refetchData,
    parseDate,
  }
}
